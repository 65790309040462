import moment from "moment/moment";
import fetchData from "../axios/fetchData";
import { telemetry } from "../axios/serverUrl";
import { symbolList } from "./common";
import {getLabel} from "./device";

export const handleChartData = (
  chartData,
  item,
  itemKey,
  rangeState,
  isChangeChart,
  key,
  dropdownOptionsBioKey
) => {
  if (item?.[itemKey]) {
    const seriesObject = {
      ...item.itemCoil,
      id: item.itemCoil.id.id + Math.floor(Math.random() * 100 + 1),
      coilId: item.itemCoil.id.id,
      name: getLabel(item.itemCoil),
      type: item[itemKey]?.length <= 1 ? "scatter" : "line",
      smooth: false,
      lineStyle: {
        width: 2.5,
      },
      telemetryKey: itemKey,
      showSymbol: false,
      data: item?.[itemKey]?.map((item) => {
        return {
          value: [
            item.ts,
            itemKey === "air_velocity"
              ? (item.value * 196.85).toFixed(2)
              : item.value
              ? (+item.value).toFixed(2)
              : item.value,
          ],
        };
      }),
      timestamp: item?.[itemKey]?.map((item) => {
        return moment(item.ts).format("MMM DD");
      }),
    };
    const values = seriesObject?.data
      ?.map((item) => parseFloat(item.value[1]))
      .filter((value) => !isNaN(value));

    let isSeriesAlredyPresent = false;
    if (chartData.series.find((item) => item.coilId === seriesObject?.coilId)) {
      isSeriesAlredyPresent = true;
    }
    const minValueTemp1 = chartData?.series?.map((item) =>
      Math.min(
        ...item?.data
          ?.map((item) => parseFloat(item.value[1]))
          .filter((value) => !isNaN(value))
      )
    );
    const minValue =
      minValueTemp1.length > 0
        ? Math.min(...minValueTemp1) > Math.min(...values)
          ? Math.min(...values)
          : Math.min(...minValueTemp1)
        : Math.min(...values);

    const maxValue = Math.max(...values);
    const adjustMin =
      itemKey === "sf_kwh"
        ? minValue - 0.02
        : maxValue > 500
        ? minValue - 2
        : minValue - (maxValue - minValue) / 4 - 1;

    var startDate = Math.min(...item[itemKey]?.map((item) => item.ts));
    var endDate = Math.max(...item[itemKey]?.map((item) => item.ts));
    var start = moment(startDate);
    var end = moment(endDate);
    var days = end.diff(start, "days");

    if (item[itemKey].length < 2) {
      startDate = Math.min(...item[itemKey]?.map((item) => item.ts));
      endDate = new Date().getTime();
      start = moment(startDate);
      end = moment(endDate);
      days = end.diff(start, "days");
    }
    // const minTimeTemp1 = chartData?.series?.map((item) =>
    //   Math.min(
    //     ...item?.data
    //       ?.map((item) => parseFloat(item.value[0]))
    //       .filter((value) => !isNaN(value))
    //   )
    // );

    // const tss = seriesObject?.data
    //   ?.map((item) => parseFloat(item.value[0]))
    //   .filter((value) => !isNaN(value));
    // const minTimeTs =
    //   minValueTemp1.length > 0 ? Math.min(...minTimeTemp1) : Math.min(...tss);

    // const maxTimeTemp1 = chartData?.series?.map((item) =>
    //   Math.min(
    //     ...item?.data
    //       ?.map((item) => parseFloat(item.value[0]))
    //       .filter((value) => !isNaN(value))
    //   )
    // );
    // const maxTss = seriesObject?.data
    //   ?.map((item) => parseFloat(item.value[0]))
    //   .filter((value) => !isNaN(value));
    // const maxTimeTs =
    //   minValueTemp1.length > 0
    //     ? Math.max(...maxTimeTemp1)
    //     : Math.max(...maxTss);
    // console.log("values", minTimeTs, maxTimeTs, adjustMin, days);

    // console.log("chartData.series", chartData.series);
    return {
      ...chartData,
      telemetryKey: itemKey,
      yAxis: {
        type: "value",
        splitLine: {
          show: true,
          lineStyle: {
            color: "#0BBBEA",
          },
        },
        splitNumber: 3,
        axisLabel: {
          show: true,
          hideOverlap: true,
          formatter: (val) => {
            // const value = itemKey === "sf_kwh" ? val * 100 : val;
            return (
              val.toFixed(1) +
              (symbolList?.[itemKey] ? symbolList[itemKey] : "")
            );
          },
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          // Be careful: it's axisTick and not axisTicks
          show: false,
        },
        min: item?.[itemKey]?.length > 1 && adjustMin > 0 ? adjustMin : null,
        // max: maxValue || 1,
      },
      xAxis: {
        type: "time",
        boundaryGap: true,
        splitLine: {
          show: true,
          interval: 15,
          lineStyle: {
            color: "#0BBBEA",
          },
        },
        axisLabel: {
          show: true,
          interval: 15,
          inside: false,
          hideOverlap: true,
          formatter: (value, index) =>
            rangeState.label === "Yesterday" ||
            rangeState.label === "Today" ||
            days < 2
              ? moment(value).format("HH:mm")
              : days < 365
              ? item[itemKey].length < 2
                ? moment(value).format("MMM DD")
                : moment(value).format("MMM DD")
              : moment(value).format("MMM DD"),
          data: item[itemKey]?.map((item) => {
            return moment(item.ts).format("MMM DD");
          }),
        },
        axisLine: {
          show: true,
          interval: "auto",
          inside: true,
        },
        // minInterval: 15,
        interval: 15,
        // min: minTimeTs,
        // max: maxTimeTs,
      },
      series: isSeriesAlredyPresent
        ? [seriesObject]
        : [...chartData.series, seriesObject],
    };
  }
};

export const getAvailableTelemetriesForDevice = async (itemCoil) => {
  try {
    return await fetchData(
        "GET",
        telemetry +
        "/" +
        itemCoil.id.entityType +
        "/" +
        itemCoil.id.id +
        "/keys/timeseries",
        {"X-Authorization": "Bearer " + localStorage.getItem("token")},
        {}
    );
  } catch (errr) {
    console.log("errr", errr);
  }
};

export const getFetchChartData = async (itemCoil, rangeState, itemKey) => {
  const startTime = new Date(rangeState.startDate).getTime();
  const endTime = new Date(rangeState.endDate).getTime();
  const days = dateConvertToDay(startTime, endTime);
  const milliseconds = dayToGetMilliSeconds(days);
  try {
    const data = await fetchData(
      "GET",
      telemetry +
        "/" +
        itemCoil.id.entityType +
        "/" +
        itemCoil.id.id +
        "/values/timeseries?keys=" +
        itemKey +
        "&startTs=" +
        startTime +
        "&endTs=" +
        endTime +
        // "&agg=AVG&interval=180000" +
        // rangeState.chartInterval,
        "&agg=AVG&interval=" +
        milliseconds,
      { "X-Authorization": "Bearer " + localStorage.getItem("token") },
      {}
    );

    if (data?.[itemKey]) {
      return { [itemKey]: data?.[itemKey], itemCoil: itemCoil };
    } else {
      return data;
    }
  } catch (errr) {
    console.log("errr", errr);
  }
};

const dateConvertToDay = (startDate, endDate) => {
  // Calculating the time difference
  // of two dates
  const Difference_In_Time = endDate - startDate;

  // Calculating the no. of days between
  // two dates
  const Difference_In_Days = Math.round(
    Difference_In_Time / (1000 * 3600 * 24)
  );

  // To display the final no. of days (result)
  return Difference_In_Days;
};

const dayToGetMilliSeconds = (days) => {
  const hours = Math.abs(Math.floor(((days * 5) / 60).toFixed(2)));
  const miliSec =
    days < 7 && hours === 0
      ? 300000
      : days < 30 && hours === 0
      ? 1800000
      : hours < 4
      ? 7200000
      : hours < 8
      ? 18000000
      : hours < 11
      ? 36000000
      : hours < 22
      ? 43200000
      : hours < 100
      ? 866400000
      : 86400000 * 30;
  return miliSec;
};
