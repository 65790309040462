import { DateRangePicker } from "@matharumanpreet00/react-daterange-picker";
import ReactECharts from "echarts-for-react";
import { connect } from "react-redux";
import { defaultRanges } from "./defaultRanges";
import { useEffect, useState } from "react";
import { TELEMETRY_OPTION_ENUMS } from "../../utils/common";
import FullScreen from "../FullScreen";
import {getFullLabel, getLabel} from "../../utils/device";

const ChartLayout = ({
  title,
  handleDatePicker,
  datePickerIsOpen,
  onChangeDateRangePicker,
  lineChartDataOption,
  handleApplyDate,
  activeList,
  handleCloseChoice,
  buildingList,
  relationList,
  selectNewKey,
  activeKey,
  keyDropdownOptions,
}) => {
  const [activeBuildingName, setActiveBuildingName] = useState("");
  const [seriesList, setSeriesList] = useState([]);
  useEffect(() => {
    const relatedBuildingId = relationList.filter(
      (coil) => coil?.CoilId === activeList[0]?.id.id
    )[0]?.BuildingId;
    setActiveBuildingName(
      buildingList.filter(
        (building) => building?.id.id === relatedBuildingId
      )[0]?.name
    );
    // eslint-disable-next-line
  }, [activeList]);

  useEffect(() => {
    if (lineChartDataOption) {
      setSeriesList(
        lineChartDataOption.map((item) => ({
          ...item,
          showModal: false,
        }))
      );
    }
  }, [lineChartDataOption]);

  const renderSubtitle = (idx) => {
    if (activeKey === "all") {
      return TELEMETRY_OPTION_ENUMS[seriesList[idx].telemetryKey];
    }
    return TELEMETRY_OPTION_ENUMS[activeKey]
      ? TELEMETRY_OPTION_ENUMS[activeKey]
      : activeKey;
  };
  return (
    <div className="layout-box chart-layout">
      <div style={{ overflow: "auto" }}>
        <div style={{ flex: " 0 0 auto" }}>
          <div className="d-flex justify-content-between align-items-center fouling-metrics-title mb-3">
            <h2 className="snapshot-title mb-0">{title}</h2>
            <span className="w-md-25 pe-1">
              {keyDropdownOptions && keyDropdownOptions?.length > 1 && (
                <select
                  className="form-select border-none"
                  aria-label="select keys"
                  onChange={(e) => {
                    selectNewKey(e.target.value);
                  }}
                >
                  {keyDropdownOptions &&
                    keyDropdownOptions?.map((value, index) => {
                      return (
                        <option key={index} value={value}>
                          {TELEMETRY_OPTION_ENUMS[value]
                            ? TELEMETRY_OPTION_ENUMS[value]
                            : value}
                        </option>
                      );
                    })}
                </select>
              )}
            </span>
          </div>
          <div className="d-flex justify-content-space-between align-items-center chart-dropdown">
            <div className="mb-4 snapshot-bradcrumb">
              <span className="breadcrumbs-title">
                {activeBuildingName}
                {/* {" > "} */}
              </span>
              {/* {activeList.map((item, index) => (
                <span className="breadcrumbs-subtitle" key={item?.name + index}>
                  {index === 0 ? "" : " , "}
                  {item?.name}
                </span>
              ))} */}
            </div>
          </div>
          <div className="position-relative">
            {seriesList &&
              seriesList?.map((chartItem, idx) => (
                <div key={idx} className="w-100">
                  <div
                    className={`w-100 fouling-metrics__head  ${
                      idx > 0 ? "no-margin mt-5" : ""
                    }`}
                  >
                    <span className="title">
                      {renderSubtitle(idx)}
                    </span>
                    {idx === 0 ? (
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ zIndex: 999 }}
                      >
                        <span
                          className="mr-3 cursor-pointer"
                          style={{
                            cursor: "pointer !important",
                          }}
                          onClick={(e) =>
                            setSeriesList([
                              ...seriesList.map((item, index) =>
                                idx === index
                                  ? { ...item, showModal: true }
                                  : { ...item }
                              ),
                            ])
                          }
                        >
                          {/* <img
                            src={ZoomIn}
                            alt="zoom"
                            style={{
                              width: "15px",
                              height: "15px",
                              cursor: "pointer",
                            }}
                          /> */}
                        </span>
                        {/* for showing only one filter */}

                        <div
                          className="d-flex align-items-center"
                          style={{
                            position: "absolute",
                            right: 30,
                            top: -2,
                            zIndex: 99,
                          }}
                        >
                          <button
                            onClick={handleDatePicker}
                            className="filter-date border-none bg-transparent"
                          >
                            <span className="mt-1">Filter Dates</span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="24"
                              viewBox="0 0 25 24"
                              fill="none"
                            >
                              <path
                                d="M3.90625 17.75C3.90625 16.7 4.28385 15.8125 5.03906 15.0875C5.79427 14.3625 6.71875 14 7.8125 14C8.90625 14 9.83073 14.3625 10.5859 15.0875C11.3411 15.8125 11.7188 16.7 11.7188 17.75C11.7188 18.8 11.3411 19.6875 10.5859 20.4125C9.83073 21.1375 8.90625 21.5 7.8125 21.5C6.71875 21.5 5.79427 21.1375 5.03906 20.4125C4.28385 19.6875 3.90625 18.8 3.90625 17.75ZM5.98958 17.75C5.98958 18.2333 6.16754 18.6458 6.52344 18.9875C6.87934 19.3292 7.30903 19.5 7.8125 19.5C8.31597 19.5 8.74566 19.3292 9.10156 18.9875C9.45747 18.6458 9.63542 18.2333 9.63542 17.75C9.63542 17.2667 9.45747 16.8542 9.10156 16.5125C8.74566 16.1708 8.31597 16 7.8125 16C7.30903 16 6.87934 16.1708 6.52344 16.5125C6.16754 16.8542 5.98958 17.2667 5.98958 17.75ZM6.77083 4L8.85417 4L8.85417 12L6.77083 12L6.77083 4ZM13.2813 6.25C13.2813 5.2 13.6589 4.3125 14.4141 3.5875C15.1693 2.8625 16.0938 2.5 17.1875 2.5C18.2813 2.5 19.2057 2.8625 19.9609 3.5875C20.7161 4.3125 21.0937 5.2 21.0937 6.25C21.0937 7.3 20.7161 8.1875 19.9609 8.9125C19.2057 9.6375 18.2812 10 17.1875 10C16.0938 10 15.1693 9.6375 14.4141 8.9125C13.6589 8.1875 13.2813 7.3 13.2813 6.25ZM15.3646 6.25C15.3646 6.73333 15.5425 7.14583 15.8984 7.4875C16.2543 7.82917 16.684 8 17.1875 8C17.691 8 18.1207 7.82917 18.4766 7.4875C18.8325 7.14583 19.0104 6.73333 19.0104 6.25C19.0104 5.76667 18.8325 5.35417 18.4766 5.0125C18.1207 4.67083 17.691 4.5 17.1875 4.5C16.684 4.5 16.2543 4.67083 15.8984 5.0125C15.5425 5.35417 15.3646 5.76667 15.3646 6.25ZM16.1458 12L18.2292 12L18.2292 20L16.1458 20L16.1458 12Z"
                                fill="#414042"
                              />
                            </svg>
                          </button>
                        </div>

                        <div
                          style={{
                            display: datePickerIsOpen ? "block" : "none",
                            boxShadow: "1px 1px 81px rgba(41,60,74,.18)",
                            zIndex: 99999,
                            top: "30px",
                            right: "10px",
                            position: "absolute",
                          }}
                        >
                          <DateRangePicker
                            definedRanges={defaultRanges}
                            open={datePickerIsOpen}
                            onChange={onChangeDateRangePicker}
                          />
                          <button
                            className="btn-cancel"
                            onClick={handleDatePicker}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn-apply"
                            onClick={handleApplyDate}
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    ) : (
                      <span
                        className="mr-3 cursor-pointer"
                        style={{
                          cursor: "pointer !important",
                          width: "14%",
                          zIndex: 999,
                        }}
                        onClick={(e) =>
                          setSeriesList([
                            ...seriesList.map((item, index) =>
                              idx === index
                                ? { ...item, showModal: true }
                                : { ...item }
                            ),
                          ])
                        }
                      >
                        {/* <img
                          src={ZoomIn}
                          alt="zoom"
                          style={{
                            width: "15px",
                            height: "15px",
                            cursor: "pointer",
                          }}
                        /> */}
                      </span>
                    )}
                  </div>

                  <div className="fouling-metrics__content negative-top overflow-scroll">
                    <ReactECharts
                      key={idx}
                      shouldSetOption={true}
                      option={chartItem}
                      style={{
                        width: "98%",
                        height: 410,
                      }}
                      className="custom-react-chart"
                      notMerge={true}
                    />
                    <FullScreen
                      key={idx}
                      title={
                        title === "Fouling Metrics"
                          ? "COIL FOULING LEVEL"
                          : renderSubtitle(idx)
                      }
                      show={chartItem.showModal}
                      onHide={() =>
                        setSeriesList([
                          ...seriesList.map((item, index) =>
                            idx === index
                              ? { ...item, showModal: false }
                              : { ...item }
                          ),
                        ])
                      }
                      children={
                        <ReactECharts
                          key={idx}
                          shouldSetOption={chartItem?.showModal}
                          option={chartItem}
                          style={{
                            height: 410,
                          }}
                          className="custom-react-chart"
                          notMerge={true}
                        />
                      }
                    />
                  </div>
                </div>
              ))}
          </div>
          <div className="recent-choice-content flex-wrap">
            {activeList &&
              activeList
                ?.slice()
                ?.sort((a, b) => a?.name?.localeCompare(b?.name))
                ?.map((item, index) => (
                  <div
                    key={item.id.id}
                    className="recent-choice-content__box mb-2"
                    style={{
                      backgroundColor: item.color,
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className="close-btn"
                      onClick={() => {
                        if (activeList.length > 1) {
                          handleCloseChoice(item);
                        }
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="17"
                        viewBox="0 0 17 17"
                        fill="none"
                      >
                        <path
                          d="M4.53329 13.4584L3.54163 12.4667L7.50829 8.50008L3.54163 4.53341L4.53329 3.54175L8.49996 7.50841L12.4666 3.54175L13.4583 4.53341L9.49163 8.50008L13.4583 12.4667L12.4666 13.4584L8.49996 9.49175L4.53329 13.4584Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                    <span title={getFullLabel(item)}>{getLabel(item)}</span>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  recentSnapList: state.coilList.recentSnapList,
  relationList: state.buildingList.relationList,
  activeList: state.coilList.activeList,
});
export default connect(mapStateToProps, {})(ChartLayout);
