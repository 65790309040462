import lineChartDataOption from "./PerformanceLineChartData";
import RenderChart from "../../../component/RenderData/RenderChart";
import {
  dropdownOptionsPreBioKey,
  dropdownOptionsPerBio,
  dropdownOptionsPerGen1,
} from "../../../utils/dataPoints";
import {usePageTracking} from "../../../analytics/analytics";

function Performance() {
  usePageTracking();
  return (
    <RenderChart
      chartOptions={lineChartDataOption}
      chartTitle="Performance Metrics"
      dropdownOptions={dropdownOptionsPerGen1}
      dropdownOptionsBio={dropdownOptionsPerBio}
      dropdownOptionsBioKey={dropdownOptionsPreBioKey}
    />
  );
}
export default Performance;
