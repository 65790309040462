/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { activeCoil } from "../../redux/actions/coilAction";
import {getAvailableTelemetriesForDevice, getFetchChartData, handleChartData} from "../../utils/chartCommon";
import ChartLayout from "../ChartLayout";
import CustomLoader from "../Loader/Loader";
import moment from "moment";
const RenderChart = ({
  activeCoil,
  activeList,
  recentList,
  buildingList,
  chartOptions,
  chartTitle,
  dropdownOptionsBio,
  dropdownOptionsBioKey,
  dropdownOptions,
}) => {
  const navigator = useNavigate();
  const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);
  const [chartData, setChartData] = useState([chartOptions]);
  const [isLoading, setLoading] = useState(false);
  const [dynamicDropdownOptions, setDynamicDropdownOptions] = useState([])
  const [key, setKey] = useState(dropdownOptions?.[0]);
  const [rangeState, setRangeState] = useState(
    chartTitle !== "Fouling Metrics"
      ? {
          startDate: new Date().setDate(new Date().getDate() - 7),
          endDate: new Date().getTime(),
          chartInterval: 86400000 / 4,
          label: "This Week",
        }
      : {
          label: "Last Year",
          startDate: Number(moment().subtract(1, "years").format("X")),
          endDate: new Date().getTime(),
          chartInterval: 86400000 * 30,
        }
  );
  const [isChangeChart, setChangeChart] = useState(false);
  const [seriesData, setSeriesData] = useState([]);
  useEffect(() => {
    if (!JSON.parse(localStorage.getItem("userDetail"))?.email) {
      navigator("/login");
      return;
    }
  }, []);

  async function setDropdownOptionsBasedOnAvailableTelemetries() {
    const responses = await Promise.all(activeList.map(getAvailableTelemetriesForDevice));
    const availableTelemetries = new Set(responses.flat());
    availableTelemetries.add("all");
    const newDynamicOptions = dropdownOptions.filter(option => availableTelemetries.has(option));
    setDynamicDropdownOptions(newDynamicOptions);
  }

  useEffect(() => {
    if (activeList) {
      setDropdownOptionsBasedOnAvailableTelemetries();
      setSeriesData([]);
      if (key !== "all") {
        applyChartDataActive(false, key);
      }
    }
  }, [activeList]);

  useEffect(() => {
    //chartData -> array of chartData, each value represent one chart configuration
    //seriesData -> contains array of values for one telemetry and itemCoil which contains info about one device/gateway
    if (seriesData?.length > 0) {
      const newChartData = seriesData?.map((item, idx) => {
        const chartDataOld = chartData.filter(
          (item1) =>
            item1.telemetryKey === Object.keys(item)?.[0] ||
            item1.telemetryKey === dropdownOptionsBioKey[Object.keys(item)?.[0]]
        );
        return handleChartData(
          chartDataOld.length > 0 && chartTitle !== "Fouling Metrics"
            ? chartDataOld[0]
            : chartDataOld.length > 0
            ? chartData?.[0]
            : chartOptions,
          item,
          Object.keys(item)?.[0],
          rangeState,
          isChangeChart,
          key,
          dropdownOptionsBioKey
        );
      });
      const newDataArray = [];
      chartData.map((item) => {
        const indexOd = newChartData.findIndex(
          (item1) =>
            item1.telemetryKey === item.telemetryKey ||
            item1.telemetryKey === dropdownOptionsBioKey[item.telemetryKey]
        );
        if (indexOd === -1 && item?.series?.length > 0) {
          newDataArray.push(item);
        }
        return true;
      });
      if (newChartData.length > 0)
        setChartData(
          key === "all" ? [...newChartData, ...newDataArray] : newChartData // handles case when there is one chart or chart per each telemetry
        );
    }
  }, [seriesData]);

  useEffect(() => {
    if (key === "all" && seriesData.length === 0) {
      setChangeChart(true);
      applyChartDataActive(true, key);
    }
  }, [seriesData, key]);

  //handle the case when selecting all telemetries, selecting one, changing telemetries, changing selection of devices...
  const applyChartDataActive = async (isSelectedDate, newKey) => {
    if (activeList.length > 0) {
      if (isSelectedDate) {
        setChartData((prev) => {
          return prev?.map((prevCharObj) => {
            return {
              ...prevCharObj,
              series: [],
            };
          });
        });
        activeList?.map(async (element) => {
          setTimeout(async () => {
            await getData(element, rangeState, newKey);
          }, 100);
        });
      } else if (chartData[0].series.length > activeList.length) {
        const differTemp = chartData[0].series.filter((val) =>
          activeList.some((val1) => val1.id.id === val.coilId)
        );
        setChartData((prev) => {
          return prev?.map((prevCharObj) => {
            return {
              ...prevCharObj,
              series: differTemp,
            };
          });
        });
      } else if (activeList.length > 1) {
        const differTemp = activeList.filter(
          (val) =>
            !chartData[0].series.some((val1) => val1.coilId === val.id.id)
        );
        if (differTemp.length !== 0) {
          differTemp?.map(async (element, index) => {
            if (index === 0) {
              await getData(element, rangeState, newKey);
            } else {
              setTimeout(async () => {
                await getData(element, rangeState, newKey);
              }, 100);
            }
          });
        } else {
          setChangeChart(true);
          activeList?.map(async (element, index) => {
            if (index === 0) {
              await getData(element, rangeState, newKey);
            } else {
              setTimeout(async () => {
                await getData(element, rangeState, newKey);
              }, 100);
            }
          });
        }
      } else {
        await getData(activeList[0], rangeState, newKey);
      }
    } else {
      setChartData((prev) => {
        return prev?.map((prevCharObj) => {
          return {
            ...prevCharObj,
            series: [],
          };
        });
      });
    }
  };

  const getData = async (itemCoil, rangeState, newKey) => {
    let keysToPass = newKey;
    if (newKey === "all") {
      keysToPass =
        itemCoil?.type !== "Biomik MK2"
          ? dynamicDropdownOptions.join(",")
          : dropdownOptionsBio.join(",");
    } else if (
      itemCoil?.type === "Biomik MK2" &&
      chartTitle !== "Fouling Metrics"
    ) {
      keysToPass = dropdownOptionsBioKey[newKey];
    }
    setLoading(true);
    const dataChart = await getFetchChartData( //returns array of values for one metric and itemCoil/device details
      itemCoil,
      rangeState,
      keysToPass,
      navigator
    );
    if (dataChart?.data?.status === 401 || dataChart?.data?.errorCode === 11) {
      navigator("/login");
      localStorage.clear();
    }
    if (typeof dataChart === "object") {
      const dataKeysArr = Object.keys(dataChart);
      if (dataChart?.errorCode) {
        setLoading(false);
        if (dataChart?.errorCode === 11 || dataChart?.errorCode === 10) {
          navigator("/login");
          localStorage.clear();
        }
      } else if (dataChart?.[keysToPass]) { //handles case when asking for one telemetry
        setSeriesData([dataChart]);
        setLoading(false);
      } else if (dataKeysArr?.length > 0) { //handles case when asking for all telemetries
        const newSeriesData = [];
        dataKeysArr.forEach((dataKey) => {
          newSeriesData.push({ [dataKey]: dataChart[dataKey], itemCoil });
        });
        setSeriesData(
          newKey !== "all"
            ? newSeriesData
            : seriesData?.length === 0
            ? newSeriesData
            : [...seriesData, ...newSeriesData]
        );
        setLoading(false);
      } else {
        setLoading(false);
      }
    } else {
      if (newKey !== "all") {
        setChartData([chartOptions]);
      }
      setLoading(false);
    }
  };

  const handleApplyDate = () => {
    setSeriesData([]);
    if (key !== "all") {
      applyChartDataActive(true, key);
    }
    setDatePickerIsOpen(!datePickerIsOpen);
  };

  const handleCloseChoice = (item) => {
    activeCoil(item.id);
  };
  const selectNewKeydata = async (newKey) => {
    setSeriesData([]);
    if (newKey !== "all") {
      applyChartDataActive(true, newKey);
    }
    setKey(newKey);
  };
  return (
    <>
      <ChartLayout
        title={chartTitle}
        handleDatePicker={() => setDatePickerIsOpen(!datePickerIsOpen)}
        handleApplyDate={handleApplyDate}
        datePickerIsOpen={datePickerIsOpen}
        onChangeDateRangePicker={(range) => {
          setRangeState(range);
        }}
        lineChartDataOption={chartData}
        stateDateRange={rangeState}
        activeList={activeList}
        handleCloseChoice={handleCloseChoice}
        recentList={recentList}
        buildingList={buildingList}
        selectNewKey={selectNewKeydata}
        keyDropdownOptions={
          dynamicDropdownOptions
        }
        activeKey={key}
      />
      <CustomLoader
        idLoading={isLoading}
        handleLoading={() => setLoading(!isLoading)}
      />
    </>
  );
};
const mapStateToProps = (state) => ({
  coilList: state.coilList.coilList,
  activeList: state.coilList.activeList,
  recentList: state.coilList.recentList,
  buildingList: state.buildingList.buildingList,
});
export default connect(mapStateToProps, { activeCoil })(RenderChart);
